.account-main-section{
    display: flex;
    width: 100%;
    height: 100vh;
    position: relative;
}

@media screen and (min-width:992px) {
    .account-main-section{
        padding-left: 260px;
        transition: padding-left  0.3s linear;
    }
    
    .account-main-section.active{
        padding-left: 65px;
    }
}

/* --- Account Left --- */
.account-side-menu{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 103;
    flex: 0 1 260px;
    width: 260px;
    height: 100vh;
    background-color: var(--darkblue);
    padding-bottom: 80px;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
    transition: width 0.3s linear, flex 0.3s linear;
}

.account-side-menu::-webkit-scrollbar{
    display: none;
}
/* --- Account Left --- */


/* --- Account Left logo start --- */
.account-side-menu .asm-logo{
    /* position: fixed; */
    position: sticky;
    top: 0;
    z-index: 1;
    height: 90px;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(10 18 26);
}

.account-side-menu .asm-logo img.asm-main-logo{
    width: 140px;
    height: 57px;
    display: block;
    margin: auto;
}

.account-side-menu .asm-logo img.asm-logo-icon{
    width: 50px;
    height: 50px;
    display: none;
    opacity: 0;
    visibility: hidden;
}

.account-side-menu .asm-logo .close-icon{
    display: none;
}

@media screen and (max-width:991px) and (min-width:0px){
    .account-side-menu .asm-logo{
        height: 90px;
    }

    .account-side-menu .asm-logo .close-icon{
        display: flex;
    }
}
/* --- Account Left logo end --- */


/* --- Account Left Side Menu Start --- */
.account-menu-bx{
    margin-top: 15px;
}

.amb-section-lable{
    position: relative;
    font-size: 12px;
    padding: 10px 10px 5px;
    margin-top: 5px;
    font-weight: 500;
    color: var(--celeste-400);
    text-align: center;
    width: 65px;
}

.account-menu-bx .amb-link{
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 46px;
    line-height: 46px;
    padding: 0 15px;
    font-size: 16px;
    color: var(--celeste-600);
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.account-menu-bx .amb-link span{
    white-space: nowrap;
    /* transition: all 0.3s ease-in-out; */
}

.account-menu-bx .amb-link svg{
    position: relative;
    top: 0px;
    width: 22px;
    height: 22px;
    flex: 0 0 22px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    transition: all 0.3s linear;
}

.account-menu-bx .amb-link.collapsed span svg{
    transform: translateY(-50%) rotate(-90deg);
    color: currentColor;
}

.account-menu-bx .amb-link span svg{
    position: absolute;
    top: 50%;
    right: 16px;
    width: 20px;
    height: 20px;
    object-position: center;
    object-fit: contain;
    transform: translateY(-50%) rotate(0deg);
    transition: all 0.3s ease-in-out;
    color: var(--electricblue);
}



.account-menu-bx .amb-link:not(.active):hover{
/* .account-menu-bx .amb-link:hover{ */
    background-color: var(--black-200);
    color: var(--celeste);
    text-shadow: 0 0 5px var(--electricblue), 0 0 15px var(--electricblue);
}

.account-menu-bx .amb-link.active{
    background-color: var(--black-200);
    color: var(--electricblue);
}

.account-menu-bx .amb-link::before{
    content: "";
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 1px;
    box-shadow: 0 0 10px 5px var(--electricblue), 0 0 20px 5px var(--electricblue);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.account-menu-bx .amb-link:not(.active):hover::before{
/* .account-menu-bx .amb-link:hover::before{ */
    opacity: 1;
    visibility: visible;
}

.account-menu-bx .amb-link::after{
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 4px;
    height: -webkit-calc(100% - 10px);
    height: -moz-calc(100% - 10px);
    height: calc(100% - 10px);
    background-color: var(--electricblue);
    -webkit-border-radius: 50px 0 0 50px;
       -moz-border-radius: 50px 0 0 50px;
            border-radius: 50px 0 0 50px;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}

.account-menu-bx .amb-link.active::after{
    opacity: 1;
}

.account-menu-bx .amb-link .amb-num{
    position: absolute;
    left: 24px;
    top: 5px;
    z-index: 1;
    width: 17px;
    height: 17px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    background-color: var(--electricblue2);
    display: block;
    color: var(--white);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 400;
    font-size: 11px;
}

/* --- Amb Drop Bx Start --- */

.amb-drop-bx .amb-drop-item{
    position: relative;
    margin-top: 5px;
}

.amb-drop-bx .amb-drop-item::after{
    content: " ";
    position: absolute;
    left: 10px;
    top: -3px;
    width: calc(100% - 20px);
    height: 1px;
    background-color: var(--celeste-100);
}
/* --- Amb Drop Bx End --- */

/* --- Account Left Side Menu End --- */

/* --- Account Left Side Menu Active Start --- */
@media screen and (min-width:992px) {
.account-side-menu.active{
    flex: 0 1 65px;
    width: 65px;
}

.account-side-menu.active:hover{
    flex: 0 1 260px;
    width: 260px;
}

.account-side-menu.active .asm-logo img.asm-main-logo{
    display: none;
    opacity: 0;
    visibility: hidden;
}

.account-side-menu.active:hover .asm-logo img.asm-main-logo{
    display: block;
    opacity: 1;
    visibility: visible;
}

.account-side-menu.active .asm-logo img.asm-logo-icon{
    display: block;
    opacity: 1;
    visibility: visible;
}

.account-side-menu.active:hover .asm-logo img.asm-logo-icon{
    display: none;
    opacity: 0;
    visibility: hidden;
}

.account-side-menu.active .amb-link{
    gap: 0;
}

.account-side-menu.active:hover .amb-link{
    gap: 10px;
}

.account-side-menu.active .amb-link svg{
    margin-left: 6.5px;
}

.account-side-menu.active:hover .amb-link svg{
    margin-left: 0px;
}

.account-side-menu.active .amb-link span{
    opacity: 0;
    visibility: hidden;
    display: none;
}

.account-side-menu.active:hover .amb-link span{
    opacity: 1;
    visibility: visible;    
    display: block;
}
}
/* --- Account Left Side Menu Active End --- */

/* --- @media 992px after Account Left Side Menu Active Start --- */
@media screen and (max-width:991px) and (min-width:0px){

    .account-side-menu{
        position: fixed;
        width: 300px;
        flex: 0 1 300px;
        transform: translateX(calc(-100% - 20px));
        transition: transform 0.3s ease-in-out ;
    }

    .account-side-menu.active{
        transform: translateX(0px);
    }
}
/* --- @media 992px after Account Left Side Menu Active End --- */


/* --- side-menu-overlay start --- */
.side-menu-overlay{
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    inset: 0;
    z-index: 102;
    background-color: var(--black-300);
    backdrop-filter: blur(5px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width:991px) and (min-width:0px){
    .side-menu-overlay{
        display: block;
    }
    
    .side-menu-overlay.active{
        opacity: 1;
        visibility: visible;
    }
}
/* --- side-menu-overlay End --- */


/* --- Account Right --- */
.account-content-area{
    position: relative;
    flex: 1 0 100%;
    width: 100%;
    background-color: var(--black-200);
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 50px;
    height: 100%;
}

.account-content-area .container-lg{
    width: 100%;
    max-width: 100%;
}


/* --- Common box start --- */
.common-box{
    position: relative;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--darkblue);
}

.common-box.cb-p-bg-none{
    padding: 0;
    background-color: transparent;
}

.common-box-heading{
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
}

.common-box-heading.cbh-big{
    font-size: 20px;
    font-weight: 500;
}

.common-box-sub-heading{
    font-weight: 400;
    color: var(--celeste-600);
    font-size: 14px;
    margin-bottom: 15px;
    margin-top: -5px;
}

.common-box-heading svg{
    color: var(--electricblue);
    width: 20px;
    height: 20px;
    margin-right: 8px;
    position: relative;
    top: -1px;
}

.common-box-heading .cbh-count{
    color: var(--celeste-400);
    float: right;
    font-weight: 500;
    width: fit-content;
    letter-spacing: 1.5px;
    font-size: 12px;
    position: relative;
    top: -1px;
    display: none;
}

.common-box-heading .cbh-count span{
    color: var(--celeste);
}

.common-box-info{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: 7px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    padding: 6px;
    color: var(--electricblue);
    transition: all 0.3s ease-in-out;
}

.common-box-info:hover{
    color: var(--electricblue);
    background-color: var(--celeste-100);
}

.common-box-info svg{
    width: 30px;
    height: 30px;
}

.common-box-link{
    display: flex;
    align-items: center;
    gap: 1px;
    position: absolute;
    right: 5px;
    top: 7px;
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
    padding: 10px;
    transition: all 0.3s ease-in-out;
}

.common-box-link:hover{
    color: var(--electricblue);
}

.common-box-link svg{
    width: 16px;
    height: 16px;
}

.common-box-sticky{
    position: sticky;
    top: 180px;
}

.cb-sticky{
    position: sticky;
}

.cbs-filter-top{
    margin-top: -15px;
    top: calc(90px + 70px);
    background-color: #0a1119 !important;
    z-index: 2;
    border-bottom: 1px solid var(--celeste-100);
    border-radius: 0px;
}
/* --- Common box end --- */


/* --- Quick link item start --- */
.quick-link-item{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    background-color: var(--black-200);
    padding: 15px 10px;
    border-radius: 10px;
}

.quick-link-item .qli-icon{
    position: relative;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    padding: 10px;
    object-fit: cover;
    object-position: center;
    background-color: var(--celeste-100);
    color: var(--celeste-600);
    transition: all 0.3s ease-in-out;
}

.quick-link-item .qli-icon svg{
    position: relative;
    z-index: 1;
}

.quick-link-item .qli-icon::after{
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
    width: 1px;
    height: 1px;
    box-shadow: 0 0 10px 5px var(--electricblue), 0 0 20px 5px var(--electricblue);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.quick-link-item .qli-text{
    font-size: 12px;
    color: var(--celeste-600);
    font-weight: 500;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.quick-link-item:hover .qli-text,
.quick-link-item:hover .qli-icon{
    color: var(--electricblue);
}

.quick-link-item:hover .qli-icon::after{
    opacity: 1;
    visibility: visible;
}

.quick-link-item:hover .qli-text{
    text-shadow: 0 0 5px var(--electricblue), 0 0 15px var(--electricblue);
}

@media screen and (max-width:575px) and (min-width:360px) {
    .col-xsm-4{
        flex: 0 0 auto;
        width: 33.33%;
    }
}
/* --- Quick link item end --- */


/* --- wallets recheck bx Start --- */
.wallets-recheck-bx{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color: var(--celeste-600);
    font-weight: 400;
    font-size: 14px;
}

.wallets-recheck-bx .wrb-icon{
    width: 36px;
    height: 36px;
    border-radius: 50px;
    padding: 6px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.wallets-recheck-bx .wrb-icon:hover{
    color: var(--electricblue);
    background-color: var(--celeste-100);
}

.wallets-balance-bx{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 134px;
}

.wallets-balance-bx .wbb-balance{
    font-size: 20px;
    color: var(--white);
    font-weight: 500;
}

.wallets-balance-bx .wbb-text{
    color: var(--celeste-600);
    font-weight: 400;
    font-size: 14px;
}


.wallets-balance-item{
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--black-200);
    padding: 14px 10px 12px;
    border-radius: 10px;
    overflow: hidden;
}

.wallets-balance-item::after{
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background-color: var(--celeste-800);
}

.wallets-balance-item.wbi-color-1::after{
    background-color: #2454ff;
}

.wallets-balance-item.wbi-color-2::after{
    background-color: rgb(8, 225, 4)
}

.wallets-balance-item.wbi-color-3::after{
    background-color: rgb(255, 122, 0)
}

.wallets-balance-item.wbi-color-4::after{
    background-color: rgb(255, 214, 50);
}

.wallets-balance-item .wbi-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste);
}

.wallets-balance-item .wbi-inner:nth-child(2){
    color: var(--celeste-600);
    font-size: 12px;
}
/* --- wallets recheck bx End --- */


/* --- Transaction Item Start --- */
.transaction-item{
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    row-gap: 25px;
    background-color: var(--black-200);
    padding: 15px 10px;
    border-radius: 10px;
}

.transaction-item .ti-inner{
    width: 16.66%;
    flex: 0 0 16.66%;
    padding: 0 10px;
}

.transaction-item .ti-inner.ti-i-pl{
    padding-left: 30px;
    position: relative;
}

.transaction-item .ti-inner .ti-i-icon{
    position: absolute;
    left: 0;
    top: -3px;
    width: 20px;
    height: 20px;
}

.transaction-item .ti-inner .ti-i-info{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}

.transaction-item .ti-inner .ti-i-data{
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste);
    margin-top: 5px;
}

.transaction-item .ti-inner .tit-color1{
    color: var(--error);
}

.transaction-item .ti-inner .tit-color2{
    color: var(--success);
}

.transaction-item .ti-inner .tit-color3{
    color: var(--pending);
}

@media screen and (max-width:1300px){
    .transaction-item .ti-inner{
        width: 33.33%;
        flex: 0 0 33.33%;
    }
}

@media screen and (max-width:576px){
    .transaction-item .ti-inner{
        width: 50%;
        flex: 0 0 50%;
    }
}
/* --- Transaction Item End --- */


/* transaction-sort-item Start */

.transaction-item.transaction-sort-item{
    justify-content: space-between;
}

.transaction-sort-item.transaction-item .ti-inner{
    width: auto;
    flex: 0 0 auto;
}

.transaction-sort-item.transaction-item .ti-inner:last-child .ti-i-info{
    text-align: right;
}
/* --- transaction-sort-item End --- */



/* --- transaction-modal-item Start --- */


.transaction-item.transaction-modal-item{
    justify-content: space-between;
    row-gap: 10px;
}

.transaction-item.transaction-modal-item .ti-inner{
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.transaction-item.transaction-modal-item .ti-inner.ti-column{
    flex-direction: column;
    justify-content: inherit;
    align-items: inherit;
    gap: 5px;
}

.transaction-item.transaction-modal-item .ti-inner .ti-i-data{
    margin-top: 0;
    font-size: 14px;
}

.transaction-item.transaction-modal-item .ti-inner .ti-i-info{
    font-size: 14px;
}

.transaction-item.transaction-modal-item .ti-inner .ti-i-icon{
    left: 8px;
}

@media screen and (max-width:576px) {
    .transaction-item.transaction-modal-item .ti-inner .ti-i-data,
    .transaction-item.transaction-modal-item .ti-inner .ti-i-info{
        font-size: 12px;
    }
    
}
/* --- transaction-modal-item End --- */


/* --- verification status bx Start --- */
.verification-status-bx{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.verification-status-bx .vsb-level-bx{
    font-size: 24px;
    color: var(--white);
    font-weight: 600;
    white-space: nowrap;
}

.verification-status-bx .vsb-progress-bx{
    display: flex;
    align-items: center;
    gap: 15px;
}

.verification-status-bx .vsb-progress-bx .vsb-progress-bar{
    position: relative;
    width: 100%;
    flex: 0 1 100%;
    height: 4px;
    border-radius: 50px;
    background-color: var(--black-200);
}

.verification-status-bx .vsb-progress-bx .vsb-progress-bar .vp-inner{
    position: absolute;
    left: 0;
    top: 50%;
    height: 6px;
    transform: translateY(-50%);
    border-radius: 50px;
    background: var(--electricblue);
    transition: all 1s linear;
    background-image: linear-gradient(90deg, var(--electricblue), var(--azure) );
}

.verification-status-bx .vsb-progress-bx .vsb-progress-num{
    font-size: 14px;
    color: var(--celeste);
    font-weight: 500;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

/* --- Verified List css start --- */
.verification-list-bx{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 15px;
}

.verification-list-bx .vlb-item{
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 12px;
    color: var(--celeste-600);
}

.verification-list-bx .vlb-item svg{
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
}


.verification-list-bx .vlb-item.active{
    color: var(--celeste);
}

.verification-list-bx .vlb-item.active  svg{
    color: var(--electricblue);
}
/* --- Verified List css end --- */



.verification-note .vn-heading{
    font-size: 16px;
    font-weight: 500;
    color: var(--celeste);
}

.verification-note .vn-text{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
    margin-top: 5px;
}
 
/* --- verification status bx end --- */


/* --- verification status bx 2 start --- */
.verification-status-bx2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.verification-status-bx2 .vsb2-data-bx{
    display: flex;
    align-items: center;
    gap: 10px;
}

.verification-status-bx2 .vsb2-data-bx .vdb-icons-bx{
    position: relative;
    width: 48px;
    height: 48px;
}

.verification-status-bx2 .vsb2-data-bx .vdb-icons-bx > svg{
    position: absolute;
    left: 0;
    top: -1px;
    width: 48px;
    height: 48px;
    padding: 12px;
    color: var(--celeste);
}

.verification-status-bx2 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle{
    width: 100%;
    height: 100%;
    color: var(--celeste-600);
}

.verification-status-bx2 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle svg{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.verification-status-bx2.verified-step1 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle svg path:nth-child(1),
.verification-status-bx2.verified-step2 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle svg path:nth-child(2),
.verification-status-bx2.verified-step2 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle svg path:nth-child(1),
.verification-status-bx2.verified-step3 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle svg path:nth-child(3),
.verification-status-bx2.verified-step3 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle svg path:nth-child(2),
.verification-status-bx2.verified-step3 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle svg path:nth-child(1){
    color: var(--electricblue);
}

.verification-status-bx2 .vsb2-data-bx .vdb-inner .vsb2-current{
    font-size: 20px;
    color: var(--error);
    font-weight: 600;
    white-space: nowrap;
    /* margin-top: 2px; */
    margin-bottom: 2px;
    transition: all 0.3s ease-in-out;
}

.verification-status-bx2.verified-step1 .vsb2-data-bx .vdb-inner .vsb2-current{
    color: var(--electricblue-600);
}

.verification-status-bx2.verified-step2 .vsb2-data-bx .vdb-inner .vsb2-current{
    color: var(--electricblue-800);
}

.verification-status-bx2.verified-step3 .vsb2-data-bx .vdb-inner .vsb2-current{
    color: var(--electricblue);
}

.verification-status-bx2 .vsb2-data-bx .vdb-inner .vsb2-steps{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}

@media screen and (min-width:768px) {
    .verification-status-bx2.vs-fd-column{
        flex-direction: column;
        align-items: inherit;
        justify-content: inherit;
        gap: 15px;
    }
}
    
@media screen and (max-width:480px) {
    .verification-status-bx2{
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
}
/* --- verification status bx 2 end --- */


/* --- verification status Accordion css start --- */
.vs-accordion.common-accordion .acc-item{
    border: none;
    background-color: var(--darkblue);
    border-radius: 10px;
    margin-top: 15px;
}

.vs-accordion.common-accordion .acc-item .acc-heding{
    padding-left: 45px;
}

.vs-accordion.common-accordion .acc-item .acc-heding .vs-step-check,
.vs-accordion.common-accordion .acc-item .acc-heding .vs-step-num{
    position: absolute;
    top: 13px;
    left: 12px;
    width: 26px;
    height: 26px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--celeste);
    background-color: var(--celeste-100);
    transition: all 0.3s ease-in-out;
    border-radius: 50px;
}

.vs-accordion.common-accordion .acc-item.current-step .acc-heding .vs-step-num{
    background-color: var(--electricblue);
    color: var(--white);
}


.vs-accordion.common-accordion .acc-item .acc-heding .vs-step-check svg,
.vs-accordion.common-accordion .acc-item:not(.verified-step, .unverified-step, .pending-step) .acc-heding .vs-step-check{
    display: none;
}

.vs-accordion.common-accordion .acc-item.pending-step .acc-heding .vs-step-check svg:nth-child(3),
.vs-accordion.common-accordion .acc-item.unverified-step .acc-heding .vs-step-check svg:nth-child(2),
.vs-accordion.common-accordion .acc-item.verified-step .acc-heding .vs-step-check svg:nth-child(1){
    display: block;
}

.vs-accordion.common-accordion .acc-item .acc-heding .vs-step-check{
    color: var(--white);
    background-color: var(--electricblue);
    background-image: linear-gradient(90deg, var(--electricblue), var(--azure));
    padding: 5px;
}

.vs-accordion.common-accordion .acc-item.unverified-step .acc-heding .vs-step-check{
    color: var(--white);
    background: var(--error);
    background-image: none;
    padding: 5px;
}

.vs-accordion.common-accordion .acc-item.pending-step .acc-heding .vs-step-check{
    color: var(--black);
    background: var(--pending);
    background-image: none;
    padding: 5px;
}

.vs-accordion.common-accordion .acc-item .acc-heding span.down-icon.di-three{
    right: 10px;
}

.vs-accordion.common-accordion .acc-item .vs-accordion-data{
    padding: 15px;
    border-top: 1px solid var(--celeste-100);
}

.vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-label{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}

.vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-data-list{
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 5px;
}

.vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-data-list .vdl-item{
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
}

.vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-data-list .vdl-item:not(:first-child):before{
    content: none;
    position: absolute;
    left: 0px;
    top: 8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--celeste-600);
}

.vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-data-list .vdl-item svg{
    width: 18px;
    height: 18px;
    flex-basis: 18px;
    flex-shrink: 0;
    position: relative;
    top: -1px;
}

.vs-accordion.common-accordion .acc-item .vs-accordion-btns{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 15px;
    border-top: 1px solid var(--celeste-100);
}

.vs-accordion.common-accordion .acc-item.verified-step .vs-accordion-btns{
    display: none;
}

.vs-accordion.common-accordion .acc-item.disabled-step .vs-accordion-btns .common-btn-item{
    filter: grayscale(1);
    opacity: 0.7;
}

.vs-accordion.common-accordion .acc-item.disabled-step .vs-accordion-btns .common-btn-item::after{
    content: none;
}

.vs-accordion.common-accordion .acc-item.pending-step .vs-accordion-btns .common-btn-item,
.vs-accordion.common-accordion .acc-item.unverified-step .vs-accordion-btns .input-note-2,
.vs-accordion.common-accordion .acc-item.current-step .vs-accordion-btns .input-note-2{
    display: none;
}

@media screen and (min-width:1200px) {

    .vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-data-list .vdl-item:not(:first-child){
        padding-left: 15px;
    }

    .vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-data-list .vdl-item:not(:first-child):before{
        content: "";
    }
    
}

@media screen and (max-width:1199px) and (min-width:992px) {
    .vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-data-list{
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (max-width:991px) and (min-width:768px)  {

    .vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-data-list .vdl-item:not(:first-child){
        padding-left: 15px;
    }
    
    .vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-data-list .vdl-item:not(:first-child):before{
        content: "";
    }
}

@media screen and (max-width:767px) {
    .vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-data-list{
        flex-direction: column;
        align-items: flex-start;
    }
}

/* --- verification status Accordion css end --- */


/* --- Verification item css start --- */
.verification-item{
    position: relative;
    cursor: pointer;
    background-color: var(--black-200);
    padding: 15px 15px;
    /* padding-right: 50px; */
    border-radius: 8px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.3s ease-in-out;
}

.verification-item svg{
    width: 30px;
    height: 30px;
    flex-basis: 30px;
}

.verification-item svg:nth-of-type(2){
    position: absolute;
    top: 50%;
    right: 10px;
    color: var(--electricblue);
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.verification-item.vi-verified svg:nth-of-type(2){
    opacity: 1;
    visibility: visible;
}

.verification-item .vi-data .vi-lable{
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste);
}

.verification-item .vi-data .vi-data{
    font-weight: 400;
    color: var(--celeste-600);
    font-size: 12px;
    margin-top: 2px;
}
/* --- Verification item css end --- */




/* --- Specification bx Start --- */

/* specification-availability-bx */

.specification-item-bx,
.specification-heading-bx{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.specification-heading-bx{
    padding: 0 0 10px 0;
}

.specification-heading-bx .sahb-lable{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 14px;
}

.specification-item-bx{
    /* border-top: 1px solid var(--celeste-100); */
    background-color: var(--black-200);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
}

.specification-item-bx:last-child{
    margin-bottom: 0px;
}

.specification-item-bx .saib-lable{
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste);
}

.specification-item-bx .saib-icon span{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 26px;
    height: 26px;
    border-radius: 2px;
    color: var(--error);
    background-color: var(--black-200);
}

.specification-item-bx .saib-icon span svg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.specification-item-bx .saib-icon span.active{
    color: var(--success);
}
/* --- specification bx end --- */


/* --- signinup-group-detail-item start --- */
.signinup-group-detail-item{
    position: relative;
}

.signinup-group-detail-item .sgdi-lable{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}

.signinup-group-detail-item .sgdi-data{
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste);
    margin-top: 3px;
}

.sgdi-flex{
    display: flex;
    justify-content: space-between;
}

.sgdi-font-big .sgdi-lable,
.sgdi-font-big .sgdi-data{
    font-size: 16px;
}

.signinup-group-detail-item.sgdi-bottom-line{
    border-bottom: 1px solid var(--celeste-100);
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding-right: 80px;
}

.signinup-group-detail-item .sgdi-btn{
    position: absolute;
    bottom: 10px;
    right: 0px;
}
/* --- signinup-group-detail-item end --- */

/* --- Change password Bx End --- */
.change-password-bx{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.change-password-bx .cpb-inner-bx .cpb-i-lable{
    font-size: 20px;
    color: var(--white);
    font-weight: 600;
    white-space: nowrap;
    margin-bottom: 2px;
}

.change-password-bx .cpb-inner-bx .cpb-i-date{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}
/* --- Change password Bx End --- */


/* --- Authentication Item Start--- */
.authentication-item{
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 25px;
    background-color: var(--black-200);
    padding: 13px 15px;
    border-radius: 10px;
    align-items: center;
    overflow: hidden;
}

.authentication-item .ai-i-heading{
    font-size: 16px;
    font-weight: 500;
    color: var(--celeste);
}

.authentication-item .ai-i-tag{
    font-size: 12px;
    color: var(--success);
}

.authentication-item .ai-i-text{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
    margin-top: 5px;
}

.authentication-item .authentication-prossesing{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    color: var(--celeste);
    font-weight: 600;
    background-color: var(--celeste-100);
}
/* --- Authentication Item End--- */


/* --- Device Management Start --- */

.device-management-item{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    row-gap: 25px;
    background-color: var(--black-200);
    padding: 15px 10px;
    border-radius: 10px;
    padding-right: 120px;
}

.device-management-item .dm-inner{
    width: 25%;
    flex: 0 0 25%;
    padding: 0 5px;
}

.device-management-item .dm-inner .dm-i-info{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}

.device-management-item .dm-inner .dm-i-data svg{
    width: 20px;
}

.device-management-item .dm-inner .dm-i-data{
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste);
    margin-top: 5px;
}

.device-management-item .dm-inner.dm-inner-btn{
    position: absolute;
    right: 10px;
    top: 15px;
    width: initial;
    flex: initial;
}

.device-management-item .dm-inner .dm-i-data.dm-i-delete,
.device-management-item .dm-inner .dm-i-data.dm-i-active{
    display: flex;
    align-items: center;
    gap: 5px;
}

.device-management-item .dm-inner .dm-i-data.dm-i-active .dm-i-actiev-icon{
    width: 12px;
    height: 12px;
    background-color: var(--success);
    border-radius: 50%;
    box-shadow: 0 0 10px var(--success);
}


.device-management-item .dm-inner .dm-i-data.dm-i-delete{
    color: var(--celeste-500);
}

.device-management-item .dm-inner .dm-i-data.dm-i-delete:hover{
    color: var(--error);
    cursor: pointer;
}

@media screen and (max-width:1024px){
    .device-management-item .dm-inner{
        width: 50%;
        flex: 0 0 50%;
    }
}

@media screen and (max-width:576px){
    .device-management-item{
        padding-right: 10px;
    }

    .device-management-item .dm-inner{
        width: 50%;
        flex: 0 0 50%;
    }

    .device-management-item .dm-inner.dm-inner-btn{
        position: initial;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .device-management-item .dm-inner.dm-inner-btn .dm-i-data{
        margin-top: 0;
    }
}
/* --- Device Management End --- */



/* ---  --- */
.dvb-list-data-bx,
.dvb-list-heading-bx{
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 10px;
    row-gap: 20px;
    padding-right: 85px;
}

.dvb-list-data-bx .dldb-item,
.dvb-list-heading-bx .dlhb-item{
    flex: 0 0 20%;
}

.dvb-list-data-bx .dldb-item:last-child,
.dvb-list-heading-bx .dlhb-item:last-child{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}



.dvb-list-data-bx .dldb-item .dldb-lable,
.dvb-list-heading-bx .dlhb-item{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}

.dvb-list-data-bx{
    background-color: var(--black-200);
    padding: 15px 10px;
    border-radius: 8px;
    margin-top: 10px;
    padding-right: 80px;
}

.dvb-list-data-bx .dldb-item .dldb-lable{
    display: none;
}

.dvb-list-data-bx .dldb-item .dldb-data{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste);
    line-height: normal;
}

.dvb-list-data-bx .dldb-item .dldb-data.dldb-view{
    color: var(--celeste-800);
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: fit-content;
}

.dvb-list-data-bx .dldb-item .dldb-data.dldb-view:hover{
    color: var(--electricblue);
}

.dvb-list-data-bx .dldb-item .dldb-data svg{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

@media screen and (max-width:1200px) {

    .dvb-list-heading-bx{
        display: none;
    }

    .dvb-list-data-bx .dldb-item .dldb-lable{
        display: block;
    }

    .dvb-list-data-bx .dldb-item,
    .dvb-list-heading-bx .dlhb-item{
        flex: 0 0 33.33%;
    }

    .dvb-list-data-bx .dldb-item .dldb-data{
        margin-top: 5px;
    }
}

@media screen and (max-width:576px) {
    .dvb-list-data-bx .dldb-item,
    .dvb-list-heading-bx .dlhb-item{
        flex: 0 0 50%;
    }
}

@media screen and (max-width:576px) {
    .dvb-list-data-bx,
    .dvb-list-data-bx{
        padding-right: 10px;
    }

    .dvb-list-data-bx .dldb-item:last-child, 
    .dvb-list-heading-bx .dlhb-item:last-child{
        position: initial;
        transform: none;
    }

    /* .dvb-list-data-bx .dldb-item .dldb-data.dldb-view{
        color: var(--electricblue);
        padding: 4px 15px;
    } */
}


/* --- Document reject reason bx Start --- */

.document-reject-reason-bx {
    background-color: var(--black-200);
    padding: 15px 10px;
    border-radius: 8px;
}

.document-reject-reason-bx .drrb-lable{
    font-weight: 500;
    color: var(--error);
    font-size: 14px;
}

.document-reject-reason-bx .drrb-lable svg{
    width: 24px;
    height: 24px;
    margin-right: 5px;
    position: relative;
    top: -1px;
}

.document-reject-reason-bx .drrb-text{
    font-size: 15px;
    font-weight: 500;
    color: var(--celeste);
    margin-top: 10px;
}

.custom-modal-data .lg-react-element{
    display: flex;
    flex-wrap: wrap;
}
/* --- Document reject reason bx End --- */

/* ---  --- */



/* --- wallet balances bx Start --- */
.wallet-balances-bx{
    position: relative;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-direction: column;
    gap: 15px;
}

.wallet-balances-bx .wbb-inner-bx .wbb-i-label{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}

.wallet-balances-bx .wbb-inner-bx .wbb-i-balances{
    font-size: 28px;
    color: var(--white);
    font-weight: 600;
    white-space: nowrap;
    margin-top: 2px;
}

.wallet-balances-bx .wbb-inner-bx .wbb-i-balances span{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 14px;
    margin-left: 5px;
}

.wallet-balances-bx .wbb-inner-bx .wbb-i-hide-btn{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: fit-content;
    padding: 5px 0;
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
    transition: all 0.3s ease-in-out;
    margin-top: 2px;
}

@media screen and (min-width:576px) {   
    .wallet-balances-bx .wbb-inner-bx .wbb-i-hide-btn:hover{
        color: var(--celeste);
    }
}

.wallet-balances-bx .wbb-inner-bx .wbb-i-hide-btn:active{
    color: var(--celeste);
}

.wallet-balances-bx .wbb-inner-bx .wbb-i-hide-btn svg{
    width: 20px;
    height: 20px;
}


@media screen and (max-width:460px) {
    .wallet-balances-bx{
        flex-direction: column;
        justify-content: initial;
        align-items: initial;
    }

    .wallet-balances-bx .wbb-inner-bx .wbb-i-hide-btn{
        position: absolute;
        right: 0px;
        top: -5px;
    }

    .wallet-balances-bx .common-btn-item{
        align-self: flex-end;
    }
}

.wallet-balances-bx.IB-wbb{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width:392px) {
    .wallet-balances-bx.IB-wbb{
        flex-direction: column;
        justify-content: inherit;
        align-items: inherit;
    }
}

/* --- wallet balances bx End --- */

/* --- wallet catd item Start --- */
.wallet-card-item{
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--black-200);
    padding: 15px;
    border-radius: 10px;
    gap: 10px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.wallet-card-item:hover{
    box-shadow: 0 0 50px -40px var(--electricblue)
}

.wallet-card-item .wci-link-bx{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
}

.wallet-card-item .wci-top-bx{
    display: flex;
    align-items: center;
    gap: 10px;
}

.wallet-card-item .wci-top-bx .wci-img{
    width: 38px;
    height: 38px;
    flex: 0 0 38px;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
}

.wallet-card-item .wci-top-bx .wci-data-bx .wci-d-heading{
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste);
}

.wallet-card-item .wci-top-bx .wci-data-bx .wci-d-id{
    position: relative;
    z-index: 2;
    cursor: pointer;
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 2px;
    transition: all 0.3s ease-in-out;
}

.wallet-card-item .wci-top-bx .wci-data-bx .wci-d-id svg{
    width: 20px;
    height: 20px;
    object-position: center;
    object-fit: cover;
    position: relative;
    top: -2px;
    transition: all 0.3s ease-in-out;
}

.wallet-card-item .wci-top-bx .wci-data-bx .wci-d-id:hover{
    color: var(--celeste);
}

.wallet-card-item .wci-other-bx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: var(--celeste-600);
}

.wallet-card-item .wci-other-bx .wci-o-data{
    color: var(--celeste);
}

.wallet-card-item .wci-other-bx.wci-balances-bx{
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
}

.wallet-card-item .wci-other-bx.wci-balances-bx{
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
}

.wallet-card-item .wci-other-bx.wci-balances-bx .wci-o-data{
    font-size: 20px;
    color: var(--white);
    font-weight: 600;
    white-space: nowrap;
}

.wallet-card-item .wci-favorites{
    position: absolute;
    right: 13px;
    top: 13px;
    width: 30px;
    height: 30px;
    padding: 4px;
    border-radius: 50%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--celeste-600);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.wallet-card-item .wci-favorites.like{
    color: var(--electricblue);
    opacity: 1;
    visibility: visible;
}

.wallet-card-item .wci-favorites.like svg{
    fill: currentColor;
}

@media screen and (min-width:768px) {

    .wallet-card-item .wci-top-bx .wci-data-bx .wci-d-id svg,
    .wallet-card-item .wci-favorites{
        opacity: 0;
        visibility: hidden;
    }

    .wallet-card-item .wci-top-bx .wci-data-bx .wci-d-id:hover svg,
    .wallet-card-item:hover .wci-favorites{
        opacity: 1;
        visibility: visible;
    }

    .wallet-card-item .wci-favorites:hover{
        color: var(--electricblue);
        background-color: var(--celeste-100);
    }
}
/* --- wallet catd item End --- */


/* --- filter-bx Start --- */
.filter-bx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.filter-bx.afb-filter .signinup-date-group,
.filter-bx.cfb-filter .signinup-date-group,
.filter-bx.ib-chart-filter .signinup-date-group,
.filter-bx.mt5-top-filter .signinup-date-group{
    width: 150px;
}

.filter-bx.afb-filter{
    align-items: flex-end;
}

.filter-bx.afb-filter .filter-right{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.filter-bx.cfb-filter,
.filter-bx.ib-chart-filter,
.filter-bx.mt-5-chart-filter{
    flex-direction: column;
    justify-content: inherit;
    align-items: inherit;
    gap: 20px;
    margin-bottom: -20px;
    position: relative;
    z-index: 1;
}

.filter-bx.cfb-filter,
.filter-bx.ib-chart-filter{
    margin-bottom: 0px;
    gap: 15px;
}

@media screen and (max-width:991px) {
    .filter-bx.transactions-bottom-filter,
    .filter-bx.ib-bottom-filter{
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        padding: 15px;
        background-color: #0a1119;
        border-top: 1px solid var(--celeste-100);
    }
}

@media screen and (max-width:768px) {

    .filter-bx.mt5-top-filter{
        flex-direction: column;
        justify-content: inherit;
        align-items: inherit;
        gap: 20px;
    }

    .filter-bx.mt5-top-filter .filter-right > *{
        width: 50%;
    }
}

@media screen and (max-width:700px) {

    .filter-bx.afb-filter{
        flex-direction: column;
        justify-content: inherit;
        align-items: inherit;
        gap: 15px;
    }

    .filter-bx.afb-filter .filter-right > .signinup-date-group{
        width: 50%;
    }
}

@media screen and (max-width:420px) {

    .filter-bx.afb-filter .filter-right{
        flex-wrap: wrap;
    }

    .filter-bx.afb-filter .filter-right > .signinup-date-group{
        width: calc(50% - 5px);
    }
}
/* --- filter-bx End --- */

/* --- Filter checkbox group Start --- */
.filter-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.filter-checkbox-group > div{
    width: 100%;
}

.filter-checkbox-group input {
    display: none;
}

.filter-checkbox-group label {
    cursor: pointer;
    position: relative;
    color: var(--celeste-600);
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 4px 0;
    padding: 5px 0px 5px 0px;
    width: fit-content;
    display: flex;
    align-items: center;
    border-radius: 50px;
    background-color: transparent;
    transition: 0.3s ease-in-out;
}

.filter-checkbox-group label span.check-icon {
    width: 22px;
    height: 22px;
    flex: 0 0 22px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: transparent;
    background-position: center;
    box-shadow: 0 0 0px 4px var(--darkblue) inset;
    border: 1px solid var(--celeste-600);
    transition: 0.3s ease-in-out;
}

/* .filter-checkbox-group label:hover {
    color: var(--celeste);
} */

.filter-checkbox-group input:checked + label {
    color: var(--celeste);
}

.filter-checkbox-group input:checked + label span.check-icon {
    background-color: var(--electricblue-500);
    border-color: var(--electricblue-500);
}

.filter-checkbox-group.check-square input:checked+label span.check-icon {
    background: url(../img/icons/check.svg);
    border-color: var(--electricblue-500);
    background-color: var(--electricblue-500);
    background-repeat: no-repeat;
    background-position: center;
    background-size: calc(100% - 4px);
    box-shadow: none;
}

.filter-checkbox-group.check-icon-hide label span.check-icon {
    display: none;
}

.filter-checkbox-group.check-square label span.check-icon {
    border-radius: 4px;
}

.filter-checkbox-group.fcg-flex-row {
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    /* overflow: hidden;
    overflow-x: auto; */
}


.filter-checkbox-group.fcg-flex-row {
    position: relative;
    z-index: 1;
}



.fdi-full-drop.filter-drop-item .filter-drop-bx,
.fdi-full-drop.filter-drop-item .filter-checkbox-group.fcg-flex-row{
    width: 100%;
}

.filter-checkbox-group.fcg-flex-row::-webkit-scrollbar{
    display: none;
}

.filter-checkbox-group.fcg-flex-row label {
    cursor: pointer;
    position: relative;
    padding: 5px 15px;
    font-size: 14px;
    color: var(--celeste-800);
    background-color: var(--black-200);
    font-weight: 500;
    border-radius: 50px;
    white-space: nowrap;
    width: auto;
    flex: 0 0 auto;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.filter-checkbox-group.fcg-flex-row label::after {
    content: " ";
    position: absolute;
    inset: 0;
    z-index: -1;
    border-color: var(--electricblue-500);
    background: linear-gradient(90deg, var(--electricblue-400), var(--azure-400));
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.filter-checkbox-group.fcg-flex-row label:hover{
    background-color: var(--black-200) !important;
}

.filter-checkbox-group.fcg-flex-row input:checked + label {
    color: var(--white);
    background-color: transparent !important;
}

.filter-checkbox-group.fcg-flex-row input:checked + label::after {
    opacity: 1;
}


.filter-sapretor{
    width: 100%;
    height: 1px;
    border-radius: 50px;
    background-color: var(--celeste-100);
    margin: 20px 0;
}
/* --- Filter checkbox group End --- */

/* --- Filter Drop Item Start --- */
.filter-sort-bx {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid var(--celeste-300);
}


.filter-drop-item:hover .filter-sort-bx,
.filter-drop-item.active .filter-sort-bx {
    border-color: var(--electricblue2-800);
}

.filter-sort-bx .filter-sort-label{
    cursor: pointer;
    position: relative;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--celeste-600);
    border: 0;
    font-size: 14px;
    background-color: var(--darkblue);
    width: 100%;
    height: 44px;
    outline: none;
    padding: 10px 44px 10px 12px;
    white-space: nowrap;
    transition: all 0.3s ease;
}

.fdih-36 .filter-sort-bx .filter-sort-label{
    height: 36px;
    padding: 10px 36px 10px 12px;
}


.filter-sort-bx .filter-sort-label:hover{
    color: var(--celeste);
}

.filter-sort-bx .filter-sort-label svg{
    position: absolute;
    right: 0;
    top: 0;
    height: 44px;
    width: 44px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: center;
    transition: all 0.3s ease-in-out;
}

.filter-sort-bx .filter-sort-label:hover svg{
    color: var(--electricblue);
}

.active > .filter-sort-bx .filter-sort-label svg{
    color: var(--electricblue);
    transform: rotate(180deg) ;
}




.fdih-36 .filter-sort-bx .filter-sort-label svg{
    position: absolute;
    right: 0;
    top: 0;
    height: 36px;
    width: 36px;
}

.filter-sort-bx .filter-sort-btn{
    cursor: pointer;
    position: relative;
    height: 44px;
    width: 44px;
    flex: 0 0 44px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--darkblue);
    transition: all 0.3s ease-in-out;
}

.filter-sort-bx .filter-sort-btn:hover{
    color: var(--electricblue);
}

.filter-sort-bx .filter-sort-btn::after{
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: calc(100% - 15px);
    background-color: var(--celeste-100);
}

.filter-sort-bx .filter-sort-btn svg{
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}


.filter-drop-item{
    position: relative;
}

.filter-drop-item .filter-drop-bx{
    position: absolute;
    /* top: calc(100%); */
    right: 0;
    z-index: 0;
    visibility: hidden;
    z-index: 3;
    top:auto
}

.filter-drop-item.fdb-view-bl .filter-drop-bx{
    bottom: calc(100%);
    left: 0;
    right: inherit;
    top: inherit;
}

.filter-drop-item.active .filter-drop-bx{
    visibility: visible;
}

.filter-drop-item .filter-drop-bx .filter-drop-inner{
    border-radius: 5px;
    max-height: 215px;
    background-clip: padding-box;
    background-color: var(--darkblue);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
            border: 1px solid var(--celeste-200);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    overflow-y: auto;
    -webkit-transform: translateY(2px);
       -moz-transform: translateY(2px);
        -ms-transform: translateY(2px);
         -o-transform: translateY(2px);
            transform: translateY(2px);
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
    -webkit-box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
       -moz-box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
            box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
}

.filter-drop-item .filter-drop-bx .filter-drop-inner::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.filter-drop-item .filter-drop-bx .filter-drop-inner::-webkit-scrollbar-track {
    background-color: var(--darkblue);
}

.filter-drop-item .filter-drop-bx .filter-drop-inner::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--celeste-600);
}

.filter-drop-item .filter-drop-bx .filter-drop-inner::-webkit-scrollbar-thumb:hover {
    background-color: var(--celeste-800);
}

.filter-drop-item.active .filter-drop-bx .filter-drop-inner{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}

.filter-drop-item .filter-drop-bx .filter-drop-inner::after{
    content: none;
    width: 16px;
    height: 16px;
    background-color: var(--darkblue);
    border: 1px solid var(--celeste-200);
    border-right: none;
    border-bottom: none;
    position: absolute;
    right: 18px;
    top: -8.8px;
    transform: rotate(45deg);
}

.filter-drop-lable{
    gap: 0 !important;
    flex-wrap: wrap !important;
}

.filter-drop-lable label{
    margin: 0;
    border-radius: 0 !important;
    width: 100% !important;
    padding: 10px 15px !important;
    background-color: transparent !important;
}

/* --- Filter Drop Item End --- */


/* --- wallet-overview-bx Start --- */
.wallet-overview-bx{
    background-color: var(--black-200);
    padding: 10px;
    border-radius: 10px;
}

.wallet-overview-bx .wob-lable{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}

.wallet-overview-bx .wob-data{
    font-size: 16px;
    font-weight: 500;
    color: var(--celeste);
    margin-top: 3px;
}
/* --- wallet-overview-bx End --- */


/* --- --- */

.wallet-current-bx {
    width: 100%;
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--darkblue);
}

.wallet-current-bx .wcb-img{
    width: 48px;
    height: 48px;
    flex: 0 0 48px;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
}

.wallet-current-bx  .wcb-data-bx .wcb-d-heading{
    font-size: 18px;
    font-weight: 500;
    color: var(--celeste);
}

.wallet-current-bx  .wcb-data-bx .wcb-d-id{
    position: relative;
    z-index: 2;
    cursor: pointer;
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 0px;
    transition: all 0.3s ease-in-out;
}

.wallet-current-bx  .wcb-data-bx .wcb-d-id svg{
    width: 20px;
    height: 20px;
    object-position: center;
    object-fit: cover;
    position: relative;
    top: -2px;
    transition: all 0.3s ease-in-out;
}

.wallet-current-bx  .wcb-data-bx .wcb-d-id:hover{
    color: var(--celeste);
}

.wallet-current-bx  .wcb-down-icon{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--celeste-600);
    transform-origin: center;
    transition: all 0.3s ease-in-out;
}

.wallet-drop-item.active  .wallet-current-bx  .wcb-down-icon{
    color: var(--electricblue);
    transform: translateY(-50%) rotate(180deg) ;
}



.wallet-drop-item{
    position: relative;
    max-width: 320px;
    width: 100%;
}

.wallet-drop-item .wallet-drop-bx{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    padding-top: 88px;
    visibility: hidden;
    z-index: 3;
    width: 100%;
}

.wallet-drop-item .wallet-drop-bx .wallet-drop-inner{
    max-height: calc(59px * 4);
    border-radius: 12px;
    background-clip: padding-box;
    background-color: var(--darkblue);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: 1px solid var(--celeste-200);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transform: translateY(5px);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
    overflow: hidden;
    overflow-y: auto;
}

.wallet-drop-item .wallet-drop-bx .wallet-drop-inner::-webkit-scrollbar{
    display: none;
}

.wallet-drop-item.active .wallet-drop-bx{
    visibility: visible;
}

.wallet-drop-item.active  .wallet-drop-bx .wallet-drop-inner{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}


.wallet-list-item {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.wallet-list-item:hover{
    background-color: var(--black-200);
}

.wallet-list-item .wli-img{
    width: 38px;
    height: 38px;
    flex: 0 0 38px;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
}

.wallet-list-item .wli-data-bx .wli-d-heading{
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste);
}

.wallet-list-item .wli-data-bx .wli-d-id{
    cursor: pointer;
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 0px;
    transition: all 0.3s ease-in-out;
}

/* @media screen and (max-width:576px) {
    .wallet-drop-item{
        max-width: 100%;
    }

    .wallet-current-btns .common-btn-item{
        width: calc(50% - 4px);
    }
} */
/* --- --- */


/* --- Payment Method Card Start --- */
.payment-method-card{
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    padding: 10px;
    background-color: var(--darkblue);
    transition: all 0.3s ease-in-out;
}

.payment-method-card:hover{
    box-shadow: 0 0 0px 1px var(--electricblue-600);
}

.payment-method-card .pmc-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px;
    border-bottom: 1px solid var(--celeste-100);
    background-color: var(--black-200);
    border-radius: 10px 10px 0 0;
}


.payment-method-card .pmc-inner .pmc-heading-bx{
    display: flex;
    align-items: center;
    gap: 10px;
}

.payment-method-card .pmc-inner .pmc-heading-bx .pmc-img{
    flex: 0 0 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    width: 40px;
}

.payment-method-card .pmc-inner .pmc-heading-bx .pmc-label{
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    color: var(--white);
}

.payment-method-card .pmc-unavailable{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--electricblue);
    gap: 3px;
    font-size: 12px;
    display: none;
    flex-wrap: nowrap;
}

.payment-method-card .pmc-unavailable svg{
    width: 18px;
    height: 18px;
    position: relative;
    top: -1px;
}

.payment-method-card .pmc-info{
    padding: 10px 15px;
    background-color: var(--black-200);
    border-radius: 0 0 10px 10px;
}

.payment-method-card .pmc-info .pmc-i-item{
    font-weight: 400;
    color: var(--celeste-800);
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    letter-spacing: 0.6px;
}

/* if Card is Unavailable */

.payment-method-card.unavailable{
    pointer-events: none;
}

.payment-method-card.unavailable .pmc-unavailable{
    display: flex;
}

.payment-method-card.unavailable .pmc-inner .pmc-heading-bx .pmc-img{
    filter: grayscale(1);
    opacity: 0.7;
}

.payment-method-card.unavailable .pmc-inner .pmc-heading-bx .pmc-label{
    opacity: 0.7;
    color: var(--celeste-600);
}

.payment-method-card.unavailable .pmc-info .pmc-i-item{
    opacity: 0.7;
    color: var(--celeste-600);
}


.seleted-payment-methods{
    display: flex;
    align-items: center;
    gap: 10px;
}

.seleted-payment-methods .spmc-img{
    flex: 0 0 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    width: 40px;
}

.seleted-payment-methods .spmc-label{
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    color: var(--white);
}

/* --- Payment Method Card End --- */


/* --- Payment tab bx start --- */
.payment-tab-bx{
    display: flex;
    gap:10px;
    margin-bottom: 15px;
}

.payment-tab-item{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste-600);
    padding: 8px 8px;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.payment-tab-item .payment-img {
    width: 40px;
    height: 40px;
    padding: 3px;
    -o-object-position: center;
    object-position: center;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid transparent;
    margin-right: 10px;
    background-color: var(--white);
    opacity: 0.8;
    transition: all 0.3s ease-in-out;
}

.payment-tab-item:hover{
    background-color: var(--black-200);
    color: var(--celeste);
}

.payment-tab-item.active{
    color: var(--electricblue);
    background-color: var(--black-200);
}

.payment-tab-item.active .payment-img,
.payment-tab-item:hover .payment-img{
    opacity: 1;
}

/* --- --- */

.payment-tab-bx.ptb-big-bx {
    gap: 0;
    background-color: var(--black-200);
    border-radius: 12px;
}

.payment-tab-bx.ptb-big-bx .payment-tab-item {
    background-color: transparent;
    border-radius: 0;
}

.payment-tab-bx.ptb-big-bx .payment-tab-item .payment-img {
    width: 90px;
    height: 50px;
}

@media screen and (max-width:500px) {
    .payment-tab-bx.ptb-big-bx .payment-tab-item {
        width: 25%;
    }

    .payment-tab-bx.ptb-big-bx .payment-tab-item .payment-img {
        width: 100%;
        height: 40px;
    }
}

/* --- Payment tab bx end --- */

/* --- Payment data bx start --- */
.payment-data-bx{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.payment-data-bx.pdb-style-2{
    padding: 15px;
    border-radius: 10px;
    background-color: var(--black-200);
}

.payment-data-bx.pdb-style-2.pdb-padding{
    padding: 10px 15px;
}

.payment-data-bx.pdb-style-3{
    padding: 15px;
    border-radius: 10px;
    background-color: var(--white);
}

.payment-data-bx .pdb-item .pdb-i-lable{
    color: var(--celeste-600);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: -3px;
}

.payment-data-bx .pdb-item .pdb-i-data{
    font-size: 14px;
    color: var(--celeste);
    font-weight: 400;
    margin: 5px 0 0;
}

.payment-data-bx .pdb-item .pdb-i-data.pid-style-2{
    position: relative;
    padding-right: 34px;
    width: fit-content;
}

.payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: var(--celeste);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    padding: 6px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: absolute;
    right: 0;
    top: -5px;
}

.payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon:hover {
    color: var(--electricblue);
    background-color: var(--celeste-100);
}

.payment-data-bx .pdb-item .pdb-i-data strong{
    color: var(--celeste);
    font-weight: 600;
}

.minimum-pdb-item,
.upi-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.minimum-pdb-item{
    justify-content: flex-start;
    gap: 5px;
}

.upi-item.pdb-item .pdb-i-lable{
    font-size: 14px;
}

.minimum-pdb-item.pdb-item .pdb-i-lable{
    font-size: 14px;
}

.minimum-pdb-item.pdb-item .pdb-i-data{
    font-size: 18px;
    font-weight: 600;
    margin-top: 0;
    position: relative;
    top: 2px;
}

.minimum-pdb-item.pdb-item .pdb-i-data .pdb-currency{
    font-weight: 500;
    font-size: 12px;
    margin-left: 2px;
    color: var(--celeste-700);
}

.qr-code-box img {
    max-width: 200px;
    width: 100%;
    display: block;
    margin: auto;
}

.qr-code-box img:nth-child(2) {
    max-width: 320px;
}
/* --- Payment data bx end --- */

/* --- --- */
.withdraw-amount-btns{
    margin-top: -5px;
    margin-bottom: 15px;
}

.withdraw-amount-btns .common-btn-item{
    flex: auto;
    min-width: inherit;
}
/* --- --- */


/* --- Notifications Css Start --- */

.notification-item{
    position: relative;
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid var(--celeste-100);
    cursor: pointer;
}

.notification-outer:first-child .notification-item{
    padding-top: 5px;
}

.notification-outer:last-child .notification-item{
    border-bottom: none;
    padding-bottom: 5px;
}

.notification-item .ni-icon{
    position: relative;
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    margin-right: 10px;
    background-color: var(--black-200);
    transition: all 0.3s ease-in-out;
}

.notification-item.ni-new .ni-icon::after{
    content: " ";
    position: absolute;
    right: 2px;
    top: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--electricblue)
}

.notification-item:hover .ni-icon{
    color: var(--electricblue);
    background-color: var(--celeste-100);
}

.notification-item .ni-data{
    flex-grow: 1;
}

.notification-item .ni-data .ni-label{
    font-size: 16px;
    font-weight: 500;
    color: var(--celeste);
    transition: all 0.3s ease-in-out;
}

.notification-item:hover .ni-data .ni-label{
    color: var(--electricblue);
}

.notification-item .ni-data .ni-text{
    color: var(--celeste-600);
    font-size: 14px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin: 5px 0;
}

.notification-item .ni-data .ni-status-date{
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 5px;
    margin-top: 5px;
}

.notification-item .ni-data .ni-date-time,
.notification-item .ni-data .ni-status{
    color: var(--celeste-500);
    font-size: 12px;
    font-weight: 500;
}

.notification-item .ni-data .ni-status span{
    color: var(--celeste);
}

@media screen and (max-width:420px) {
    .notification-item .ni-data .ni-status-date{
        flex-direction: column;
    }
}
/* --- Notifications Css End --- */


/* --- Conversion Rate Bx Start --- */
.conversion-rate-bx{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 8px;
    margin-bottom: 18px;
}

.conversion-rate-bx .current-rate{
    color: var(--celeste-600);
    font-size: 14px;
    font-weight: 500;
    padding: 0 5px;
}

.conversion-rate-bx .current-amount{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--celeste-600);
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--black-200);
}

.conversion-rate-bx .current-amount span{
    color: var(--celeste);
    font-size: 18px;
    font-weight: 600;
    margin-left: 5px;
}

@media screen and (max-width:768px) {
    .conversion-rate-bx{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .conversion-rate-bx .current-amount{
        width: 100%;
    }
}


.conversion-rate-bx.crb-style-2{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -5px;
}

.conversion-rate-bx.crb-style-2 .current-amount{
    border: 1px solid var(--celeste-300);
    background: transparent;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    justify-content: flex-start;
}
/* --- Conversion Rate Bx End --- */


/* --- Mobile Filter Bx Css Start --- */
.mobile-filter-bx{
    display: none;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--darkblue);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.01) 0px 207px 58px, rgba(0, 0, 0, 0.08) 0px 132px 53px, rgba(0, 0, 0, 0.27) 0px 74px 45px, rgba(0, 0, 0, 0.45) 0px 33px 33px, rgba(0, 0, 0, 0.52) 0px 8px 18px, rgba(0, 0, 0, 0.53) 0px 0px 0px, rgba(255, 255, 255, 0.08) 0px 1px 1px inset, rgba(0, 0, 0, 0.25) 0px 0px 0px 1px inset;
    z-index: 103;
    border-radius: 15px 15px 0 0;
    opacity: 0;
    overflow: hidden;
    transform: translateY(100%);
    transition: all 0.3s ease-in-out;
}

.mobile-filter-bx.active{
    opacity: 1;
    transform: translateY(0);
}

.mobile-filter-bx .mfb-header{
    position: sticky;
    top: 0;
    padding: 15px;
    z-index: 1;
    background-color: var(--darkblue);
    border-bottom: 1px solid var(--celeste-100);
}

.mobile-filter-bx .mfb-header-heading{
    font-size: 18px;
    color: var(--celeste);
    font-weight: 500;
    white-space: nowrap;
}

.mobile-filter-bx .mfb-header-heading svg{
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: var(--electricblue);
}

.mobile-filter-bx .mfb-footer{
    position: sticky;
    bottom: 0;
    padding: 15px;
    border-top: 1px solid var(--celeste-100);
    z-index: 1;
    background-color: var(--darkblue);
}


.mobile-filter-bx .mfb-body{
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 211px);
    padding: 20px 15px;
}

/* --- Filter Overlay start --- */
.filter-ovarlay{
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    inset: 0;
    z-index: 102;
    background-color: var(--black-300);
    backdrop-filter: blur(5px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width:767px) and (min-width:0px){
    .mobile-filter-bx,
    .filter-ovarlay{
        display: block;
    }
    
    .filter-ovarlay.active{
        opacity: 1;
        visibility: visible;
    }
}
/* --- Filter Overlay End --- */

/* --- Mobile Filter Bx Css End--- */


/* --- --- */
.mt5-account-card{
    border-radius: 10px;
    padding: 10px;
    background-color: var(--darkblue);
}
    
.mt5-account-card .mt5-ac-inner{
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 15px 15px;
    background-color: var(--black-200);
    border-radius: 10px;
}

.mt5-account-card .mt5-ac-inner .mt5-tags-bx{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.mt5-account-card .mt5-ac-inner .mt5-tags-bx .mt5-tag-item{
    color: var(--celeste-800);
    font-size: 11px;
    border: 1px solid var(--celeste-300);
    padding: 2px 8px;
    border-radius: 50px;
    font-weight: 500;
}

.mt5-account-card .mt5-ac-inner .mt5-tags-bx .mt5-tag-item.mt-real,
.mt5-account-card .mt5-ac-inner .mt5-tags-bx .mt5-tag-item.mt5-current{
    /* color: var(--success);
    border-color: var(--success); */
    color: var(--electricblue);
    border-color: var(--electricblue);
}


.mt5-account-card .mt5-ac-inner .mt5-tags-bx .mt5-tag-item.mt-demo{
    /* color: var(--pending);
    border-color: var(--pending); */
    color: var(--electricblue);
    border-color: var(--electricblue);
}


.mt5-account-card .mt5-ac-inner .mt5-id{
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    color: var(--celeste);
    margin-top: 5px;
}


.mt5-account-card .mt5-ac-inner .mt5-balances{
    font-size: 24px;
    color: var(--white);
    font-weight: 600;
    white-space: nowrap;
    line-height: normal;
}

.mt5-account-card .mt5-ac-inner .mt5-balances span{
    font-size: 14px;
    color: var(--celeste-600);
    font-weight: 500;
    margin-left: 5px;
}

.mt5-account-card .mt5-ac-inner .mt5-other-data{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.mt5-account-card .mt5-ac-inner .mt5-other-data .mt5-od-item{
    /* width: 50%;
    flex: 0 0 50%; */
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}

.mt5-account-card .mt5-ac-inner .mt5-other-data .mt5-od-item span{
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: var(--celeste);
    margin-top: 3px;
}

.mt5-account-card .mt5-ac-inner .mt5-btns-bx{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-top: 5px;
    padding-top: 15px;
    border-top: 1px solid var(--celeste-100);
}

.mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item{
    cursor: pointer;
}

.mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item .mt5-bi-icon{
    position: relative;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    padding: 10px;
    margin: 0 auto 5px;
    object-fit: cover;
    object-position: center;
    background-color: var(--celeste-100);
    color: var(--celeste-600);
    transition: all 0.3s ease-in-out;
}

.mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item.mt5-trade .mt5-bi-icon{
    color: var(--celeste);
    background: linear-gradient(90deg, var(--electricblue-400), var(--azure-400));
}

.mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item .mt5-bi-text{
    font-size: 12px;
    color: var(--celeste-600);
    font-weight: 500;
    text-align: center;
    transition: all 0.3s ease-in-out;
}


.mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item:hover .mt5-bi-icon,
.mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item:hover .mt5-bi-text{
    color: var(--electricblue);
}

.mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item.mt5-trade:hover .mt5-bi-icon{
    color: var(--white);
    background: linear-gradient(90deg, var(--electricblue-800), var(--azure-800));
}



.mt5-account-card .mt5-ac-inner .mt5-archived-bx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-top: 5px;
    padding-top: 15px;
    border-top: 1px solid var(--celeste-100);
}

.mt5-account-card .mt5-ac-inner .mt5-archived-bx .archived-info{
    font-weight: 400;
    color: var(--celeste-600);
    font-size: 14px;
}



.mt5-account-card .mt5-ac-inner .mt5-drop-icon{
    cursor: pointer;
    width: 36px;
    height: 36px;
    background-color: transparent;
    border-radius: 5px;
    padding: 5px;
    transition: all 0.3s ease-in-out;
    margin-left: auto;
}

.mt5-account-card .mt5-ac-inner .mt5-drop-icon:hover{
    background-color: var(--celeste-100);
    color: var(--electricblue);
}

.mt5-account-card .mt5-ac-inner .mt5-drop-icon svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

/* --- --- */


/* --- create account modal --- */
.create-account-bx{
    padding: 0 15px 15px;
}

.create-account-bx.cab-pt{
    padding-top: 15px;
}


.create-account-btns-bx{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black-200);
    border-radius: 50px;
    padding: 5px;
    gap: 5px;
    width: 100%;
    margin: auto;
}

.create-account-btns-bx .cabb-item{
    position: relative;
    z-index: 1;
    cursor: pointer;
    width: 100%;
    text-align: center;
    border-radius: 50px;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste-600);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.create-account-btns-bx .cabb-item::after{
    content: " ";
    position: absolute;
    inset: 0;
    opacity: 0;
    z-index: -1;
    background: linear-gradient(90deg, var(--electricblue-400), var(--azure-400));
}

.create-account-btns-bx .cabb-item:hover{
    color: var(--celeste);
    background-color: var(--celeste-100);
}

.create-account-btns-bx .cabb-item.active{
    color: var(--white);
}

.create-account-btns-bx .cabb-item.active::after{
    opacity: 1;
}

@media screen and (max-width:420px) {
    .create-account-btns-bx .cabb-item{
        padding: 8px;
        font-size: 12px;
    }
}
/* --- create account modal --- */

/* --- Account Information Modal Start --- */
.ac-info-item{
    background-color: var(--black-200);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    row-gap: 10px;
    margin: 0 15px 15px;
}

.ac-info-item .ai-inner{
    align-items: center;
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    width: 100%;
}

.ac-info-item .ai-inner .ai-i-info {
    color: var(--celeste-600);
    font-size: 14px;
    font-weight: 500;
}

.ac-info-item .ai-inner .ai-i-data {
    color: var(--celeste);
    font-size: 14px;
    font-weight: 500;
}
/* --- Account Information Modal End --- */


/* --- Trade modal item --- */
.trading-item{
    position: relative;
    cursor: pointer;
    background-color: var(--black-200);
    padding: 15px 15px;
    padding-right: 50px;
    border-radius: 8px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.3s ease-in-out;
}

.trading-item .trading-i-img{
    width: 40px;
    height: 40px;
    flex-basis: 40px;
}

.trading-item .trading-i-heading{
    font-size: 16px;
    font-weight: 500;
    color: var(--celeste);
}

.trading-item .trading-i-text{
    font-weight: 400;
    color: var(--celeste-600);
    font-size: 12px;
    margin-top: 2px;
}

.trading-item .trading-icon{
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    padding: 8px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    color: var(--celeste-600);
}

.trading-item .trading-icon svg{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.trading-item:hover{
    border-color: var(--electricblue);
}

.trading-item:hover .trading-icon{
    color: var(--electricblue);
    opacity: 1;
}

@media screen and (max-width:576px) {
    .trading-item .trading-icon{
        opacity: 1;
    }
}

@media screen and (max-width:360px) {

    .trading-item{
        padding: 15px;    
    }

    .trading-item .trading-icon{
        display: none;
    }
}
/* --- Trade modal item --- */



/* --- --- */
.referral-bx{
    display: flex;
    justify-content: center;
    gap: 15px;
}

.referral-bx .skeletant-bx{
    width: 100%;
}

.referral-bx .referral-drop{
    width: 200px;
    flex: 0 0 200px;
}

.referral-bx .referral-drop.filter-drop-item .filter-drop-bx,
.referral-bx .referral-drop.filter-drop-item .filter-checkbox-group.fcg-flex-row{
    width: 100%;
}

.referral-bx .referral-drop .filter-sort-bx{
    border-radius: 10px;
}

.referral-links-bx{
    position: relative;
    border: 1px solid var(--electricblue-500);
    padding: 12px 10px;
    border-radius: 10px;
    padding-right: 60px;
    transition: all 0.3s ease-in-out;
    flex: 1;
}

.referral-links-bx:hover{
    cursor: pointer;
    border-color: var(--electricblue);
}

.referral-links-bx .rlb-link{
    font-size: 14px;
    color: var(--celeste-800);
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-all;
    overflow: hidden;
}

.referral-links-bx .rlb-copy-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 0;
    width: 50px;
    height: 100%;
    transition: all 0.3s ease-in-out;
    color: var(--celeste-800);
}

.referral-links-bx .rlb-copy-btn:hover{
    color: var(--electricblue);
}

.referral-links-bx .rlb-copy-btn svg{
    width: 26px;
    height: 26px;
    object-fit: cover;
    object-position: center;
}

@media screen and (max-width:576px) {
    .referral-bx{
        flex-direction: column;
    }

    .referral-links-bx,
    .referral-bx .referral-drop{
        flex: inherit;
    }
}

@media screen and (max-width:420px) {
    .referral-bx .referral-drop{
        width: 100%;
    }
}
/* --- --- */


/* --- --- */
.ib-account-summary-item{
    display: block;
    background-color: var(--black-200);
    padding: 15px 15px;
    border-radius: 8px;
}

.ib-commission-rate .icr-lable,
.ib-account-summary-item .iasi-lable{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
    margin-bottom: 8px;
}

.ib-commission-rate .icr-current,
.ib-account-summary-item .iasi-data{
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: var(--celeste);
    line-height: normal;
}

.icr-indicator{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 14px;
}
/* --- --- */



/* --- MT5 Account Details css start --- */
.mt5-account-bx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.mt5-account-bx .mt5-account-inner .mt5-tags-bx{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.mt5-account-bx .mt5-account-inner .mt5-tags-bx .mt5-tag-item{
    color: var(--celeste-800);
    font-size: 11px;
    border: 1px solid var(--celeste-300);
    padding: 2px 8px;
    border-radius: 50px;
    font-weight: 500;
}

.mt5-account-bx .mt5-account-inner .mt5-tags-bx .mt5-tag-item.mt-real,
.mt5-account-bx .mt5-account-inner .mt5-tags-bx .mt5-tag-item.mt5-current{
    /* color: var(--success);
    border-color: var(--success); */
    color: var(--electricblue);
    border-color: var(--electricblue);
}


.mt5-account-bx .mt5-account-inner .mt5-tags-bx .mt5-tag-item.mt-demo{
    /* color: var(--pending);
    border-color: var(--pending); */
    color: var(--electricblue);
    border-color: var(--electricblue);
}

.mt5-account-bx .mt5-account-inner .mt5-account-id{
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    color: var(--celeste);
    margin-top: 10px;
}

.mt5-account-bx .mt5-account-inner .mt5-account-balances{
    font-size: 24px;
    color: var(--white);
    font-weight: 600;
    white-space: nowrap;
    line-height: normal;
    margin-top: 5px;
}

.mt5-account-bx .mt5-account-inner .mt5-account-balances span{
    font-size: 14px;
    color: var(--celeste-600);
    font-weight: 500;
    margin-left: 5px;
}

@media screen and (max-width:500px) {
    .mt5-account-bx{
        flex-direction: column;
        align-items: inherit;
    }

    .mt5-account-bx .mt5-account-btns{
        justify-content: flex-end;
    }
}

/* --- MT5 Account Details css end --- */



/* --- My Client info box css start --- */
.myclient-info-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--celeste-100);
    margin-bottom: 15px;
}

.myclient-info-box .myclient-tag{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.myclient-info-box .myclient-tag .myclient-tag-item{
    color: var(--celeste-800);
    font-size: 14px;
    border: 1px solid var(--celeste-300);
    padding: 2px 8px;
    border-radius: 50px;
    font-weight: 500;
}

.myclient-info-box .myclient-tag .myclient-tag-item.myclient-current{
    color: var(--electricblue);
    border-color: var(--electricblue);
}

.myclient-info-box .myclient-id{
    font-size: 24px;
    color: var(--white);
    font-weight: 600;
    line-height: normal;
}
/* --- My Client Info Box css end --- */


/* --- Chart Flex Box Css Start --- */
.chart-flex-box{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
}

/* .chart-flex-box .cfb-left{
    flex-grow: 1;
} */

/* .chart-flex-box .cfb-right{
    position: relative;
    flex-basis: 200px;
    flex-shrink: 0;
} */

.chart-flex-box .cfb-hr{
    width: 100%;
    height: 1px;
    background-color: var(--celeste-100);
    margin: -10px 0;
}

.cfb-data-item{
    cursor: pointer;
    position: relative;
    display: block;
    background-color: var(--black-200);
    padding: 10px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 0px 1px var(--celeste-100);
}

.cfb-data-item:not(.active):hover{
    box-shadow: 0 0 0px 1px var(--celeste-100);
    background-color: var(--celeste-100);
}

.cfb-data-item.active{
    box-shadow: 0 0 0px 1px var(--electricblue);
}

.cfb-data-item .cfd-di-label{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}

.cfb-data-item .cfd-di-count{
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    color: var(--white);
    font-size: 14px;
    margin-top: 4px;
}

.cfb-data-item .cfd-di-count svg{
    width: 18px;
    height: 18px;
    position: relative;
    top: -1px;
    object-fit: contain;
    object-position: center;
}

.cfb-data-item .cfd-di-tags{
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    color: var(--celeste);
    font-size: 12px;
    margin-top: 4px;
}

.cfb-data-item.cfb-green .cfd-di-count svg,
.cfb-data-item.cfb-green .cfd-di-tags{
    color: var(--success);
}

.cfb-data-item.cfb-red .cfd-di-count svg,
.cfb-data-item.cfb-red .cfd-di-tags{
    color: var(--error);
}

.cfb-table-responsive{
    height: 200px;
    overflow: auto;
}
/* --- Chart Flex Box Css End --- */


/* --- Analytics summary page css start --- */
.summary-data-box{
    height: 100%;
    position: relative;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--darkblue);
}

.summary-data-box.sdb-bg-black{
    background-color: var(--black-300);
}

.summary-data-box .sdb-item{
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
    margin-top: 8px;
}

.summary-data-box .sdb-item div{
    color: var(--celeste);
}

.summary-data-box .sdb-item.sdb-top-item{
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    margin-top: 0;
    gap: 0;
}

.summary-data-box .sdb-item.sdb-top-item div{
    font-size: 20px;
    font-weight: 600;
    color: var(--white);
}

@media screen and (max-width:992px) {
    .summary-data-box .sdb-item.sdb-top-item,
    .summary-data-box .sdb-item{
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
    }
}
/* --- Analytics summary page css end --- */


/* --- suggest page stat --- */
.suggest-type-item{
    display: block;
    height: 100%;
    position: relative;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--darkblue);
}

.suggest-type-item .sti-heading{
    font-size: 18px;
    font-weight: 600;
    color: var(--celeste);
}

.suggest-type-item .sti-ideas{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 16px;
    margin-top: 5px;
}

.suggest-type-item .sti-btn{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 25px;
    font-size: 14px;
    font-weight: 600;
    color: var(--celeste-600);
    transition: all 0.3s ease-in-out;
}

.suggest-type-item .sti-btn svg{
    width: 20px;
    height: 20px;
    color: var(--electricblue);
    transition: all 0.3s ease-in-out;
}

.suggest-type-item:hover .sti-btn{
    color: var(--electricblue);
}

.suggest-type-item:hover .sti-btn svg{
    transform: translateX(5px);
}
/* --- suggest page end --- */

/* --- Relationship manager css start --- */
.relationship-manager-bx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.relationship-manager-bx .rmb-data-bx .rmb-d-name{
    font-size: 20px;
    color: var(--celeste);
    font-weight: 600;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.relationship-manager-bx .rmb-data-bx .rmb-d-name svg{
    background: #00000024;
    border-radius: 50px;
    padding: 8px;
    margin-right: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 5px;
}

.relationship-manager-bx .rmb-data-bx .rmb-d-name img{
    background: var(--celeste-100);
    border-radius: 50px;
    margin-right: 5px;
    width: 55px;
    height: 55px;
    margin-right: 5px;
    position: relative;
    top: -2.5px;
}

@media screen and (max-width:700px) {
    .relationship-manager-bx{
        flex-direction: column;
        justify-content: initial;
        align-items: initial;
    }
}
/* --- Relationship manager css end --- */
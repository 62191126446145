.custom-header{
    position: sticky;
    top: 0px;
    z-index: 101;
    padding: 0 15px;
    height: 90px;
    background-color: var(--darkblue);
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 40%);
}

.ch-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 15px 0;
}

.ch-inner .ch-left{
    justify-content: flex-start;
}

.ch-inner .ch-right{
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
}


/* --- Aside Menu Icon Start --- */
.menu-icon-bx {
    width: 30px;
    height: 30px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 30px;
            flex: 0 0 30px;
    display: flex;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0px;
    cursor: pointer;
}

.menu-icon-bx span {
    display: block;
    width: 100%;
    height: 3px;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    opacity: 0.8;
    background-color: var(--celeste);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

/* .menu-icon-bx span:nth-child(3) {
    width: 60%;
    margin-right: auto;
} */

.menu-icon-bx:hover span,
.menu-icon-bx.active span{
    opacity: 1;
}

.menu-icon-bx.active span:nth-child(1) {
    width: 50%;
    margin-left: auto;
}

.menu-icon-bx.active span:nth-child(2) {
    width: 90%;
}

.menu-icon-bx.active span:nth-child(3) {
    width: 50%;
    margin-right: auto;
}
/* --- Aside Menu Icon End --- */


/* --- Logo Css Start --- */
.ch-logo{
    cursor: pointer;
    display: none;
}

.ch-logo img{
    width: 140px;
    display: block;
    position: relative;
    top: -2px;
}

@media screen and (max-width:991px){
    .ch-logo:not(.vsfbsrf){
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
    }
}
/* --- Logo Css End --- */

/* --- --- */
.cr-icons-link{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px;
    color: var(--celeste);
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 1;
}

.cr-icons-link svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.cr-icons-link .cr-num{
    position: absolute;
    right: 0;
    top: 0;
    width: 17px;
    height: 17px;
    font-size: 10px;
    font-weight: 500;
    border-radius: 50px;
    background: #0294B5;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cr-drop-item:hover > .cr-icons-link,
.cr-icons-link:hover{
    color: var(--electricblue);
    background-color: var(--celeste-100);
}

.cr-drop-item .ch-user-bx .cr-icons-link{
    background-color: var(--celeste-100);
}

@media screen and (max-width:576px) {
    .ch-right > .cdi-product-drop,
    .ch-right > .cr-icons-link:not(.cr-il-noti, .cr-theme-btn){
        display: none;
    }

    .ch-right > .cr-icons-link.cr-theme-btn{
        position: absolute;
        left: 56px;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media screen and (max-width:370px) {
    .ch-right > .cr-icons-link{
        display: none;
    }
}
/* --- --- */

/* --- ch user bx Start --- */
.ch-user-bx{
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    margin-left: 0;
    
}

.ch-user-bx .cub-img-bx{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.ch-user-bx .cub-img-bx img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.ch-user-bx .cub-text-bx{
    color: var(--celeste-600);
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all 0.3s ease-in-out;
}

.cr-drop-item:hover .cub-text-bx,
.ch-user-bx:hover .cub-text-bx{
    color: var(--celeste);
}

.ch-user-bx .cub-text-bx svg{
    width: 18px;
    height: 18px;
}
/* --- ch user bx End --- */


/* --- Drop item Start --- */
.cr-drop-item{
    position: relative;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
}

.cr-drop-item .cr-drop-bx{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    padding-top: 60px;
    visibility: hidden;
}

.cr-drop-item.active .cr-drop-bx{
    visibility: visible;
}

.cr-drop-item .cr-drop-bx .cr-drop-inner{
    padding: 10px;
    -webkit-border-radius: 12px;
       -moz-border-radius: 12px;
            border-radius: 12px;
    background-clip: padding-box;
    background-color: var(--darkblue);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
            border: 1px solid var(--celeste-200);
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(5px);
       -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
         -o-transform: translateY(5px);
            transform: translateY(5px);
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
    -webkit-box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
       -moz-box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
            box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
}

.cr-drop-item.active .cr-drop-bx .cr-drop-inner{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}

.cr-drop-item .cr-drop-bx .cr-drop-inner::after{
    content: none;
    width: 16px;
    height: 16px;
    background-color: var(--darkblue);
    border: 1px solid var(--celeste-200);
    border-right: none;
    border-bottom: none;
    position: absolute;
    right: 18px;
    top: -8.8px;
    transform: rotate(45deg);
}

.cdi-main{
    width: 150px;
}

.cdi-main.w-186px{
    width: 186px;
}

.cdi-main .cdi-main-item{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 5px;
    padding: 6px 6px 6px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 14px;
    margin-top: 8px;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
}

.cdi-main .cdi-main-item img{
    width: 20px;
    height: 20px;
    opacity: 0.4;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
}

.cdi-main .cdi-main-item:hover img{
    opacity: 1;
}

.cdi-main .cdi-main-item svg{
    width: 22px;
    height: 22px;
}

.cdi-main .cdi-main-item:first-child{
    margin-top: 0;
}

.cdi-main .cdi-main-item:hover{
    color: var(--celeste);
    cursor: pointer;
}

.cdi-main .cdi-main-item.active{
    color: var(--celeste);
    cursor: pointer;
}

.cdi-main .cdi-main-item .cmi-num{
    position: absolute;
    left: 15px;
    top: 0;
    width: 17px;
    height: 17px;
    font-size: 10px;
    font-weight: 500;
    border-radius: 50px;
    background: #0294B5;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}


.cdi-user-data{
    display: block;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--celeste-200);
}

.cdi-user-data .cud-name{
    font-size: 14px;
    color: var(--celeste);
    font-weight: 500;
}

.cdi-user-data .cud-other{
    font-size: 12px;
    color: var(--celeste-600);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* --- Drop item End --- */